@font-face {
  font-family: 'icons';
  src:  url('fonts/icons.eot?8hsko8');
  src:  url('fonts/icons.eot?8hsko8#iefix') format('embedded-opentype'),
    url('fonts/icons.ttf?8hsko8') format('truetype'),
    url('fonts/icons.woff?8hsko8') format('woff'),
    url('fonts/icons.svg?8hsko8#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-import:before {
  content: "\e94f";
}
.icon-star-fill:before {
  content: "\21";
}
.icon-sorting:before {
  content: "\22";
}
.icon-refresh2:before {
  content: "\23";
}
.icon-user-icon:before {
  content: "\24";
}
.icon-download-file:before {
  content: "\25";
}
.icon-interface:before {
  content: "\26";
}
.icon-file-search:before {
  content: "\27";
}
.icon-sort-down:before {
  content: "\28";
}
.icon-sort-up:before {
  content: "\29";
}
.icon-sort-down1:before {
  content: "\2a";
}
.icon-replace:before {
  content: "\2b";
}
.icon-user-network:before {
  content: "\2c";
}
.icon-folder-network:before {
  content: "\2d";
}
.icon-flow-chart3:before {
  content: "\2e";
}
.icon-flow-chart-dots:before {
  content: "\2f";
}
.icon-poll:before {
  content: "\30";
}
.icon-eye3:before {
  content: "\31";
}
.icon-calendar-time:before {
  content: "\32";
}
.icon-delete-button-lines:before {
  content: "\33";
}
.icon-hourglass:before {
  content: "\34";
}
.icon-right-arrow-fill:before {
  content: "\35";
}
.icon-left-arrow-fill:before {
  content: "\36";
}
.icon-down-arrow-fill:before {
  content: "\37";
}
.icon-up-arrow-fill:before {
  content: "\38";
}
.icon-session-time:before {
  content: "\39";
}
.icon-time-correct:before {
  content: "\3a";
}
.icon-settings:before {
  content: "\3b";
}
.icon-settings1:before {
  content: "\3c";
}
.icon-collapse:before {
  content: "\3d";
}
.icon-expand:before {
  content: "\3e";
}
.icon-angle-left:before {
  content: "\3f";
}
.icon-angle-right:before {
  content: "\40";
}
.icon-safe-time:before {
  content: "\41";
}
.icon-user-history:before {
  content: "\42";
}
.icon-chart2:before {
  content: "\43";
}
.icon-file-network:before {
  content: "\44";
}
.icon-flow-tree:before {
  content: "\45";
}
.icon-chart1:before {
  content: "\46";
}
.icon-flow-tree1:before {
  content: "\47";
}
.icon-chart22:before {
  content: "\48";
}
.icon-flow-tree2:before {
  content: "\49";
}
.icon-flow-tree-text:before {
  content: "\4a";
}
.icon-text-file2:before {
  content: "\4b";
}
.icon-text-list-file2:before {
  content: "\4c";
}
.icon-user3:before {
  content: "\4d";
}
.icon-user-round:before {
  content: "\4e";
}
.icon-search2:before {
  content: "\4f";
}
.icon-search1:before {
  content: "\50";
}
.icon-search22:before {
  content: "\51";
}
.icon-search3:before {
  content: "\52";
}
.icon-bookmark:before {
  content: "\53";
}
.icon-bookmark-empty:before {
  content: "\54";
}
.icon-printer:before {
  content: "\55";
}
.icon-open-folder:before {
  content: "\56";
}
.icon-closed-folder:before {
  content: "\57";
}
.icon-open-folder1:before {
  content: "\58";
}
.icon-calendar-peel:before {
  content: "\59";
}
.icon-menu2:before {
  content: "\5a";
}
.icon-chevron-left:before {
  content: "\5b";
}
.icon-caret-up-two:before {
  content: "\5c";
}
.icon-chevron-right:before {
  content: "\5d";
}
.icon-chevron-up:before {
  content: "\5e";
}
.icon-chevron-down:before {
  content: "\5f";
}
.icon-angle-down:before {
  content: "\60";
}
.icon-left-arrow:before {
  content: "\61";
}
.icon-right-arrow:before {
  content: "\62";
}
.icon-down-arrow:before {
  content: "\63";
}
.icon-medical-tablets:before {
  content: "\64";
}
.icon-file-time:before {
  content: "\65";
}
.icon-line-chart-dots:before {
  content: "\66";
}
.icon-refresh-2:before {
  content: "\67";
}
.icon-line-chart-dots1:before {
  content: "\68";
}
.icon-refresh-file:before {
  content: "\69";
}
.icon-reverse:before {
  content: "\6a";
}
.icon-filter:before {
  content: "\6b";
}
.icon-text-file:before {
  content: "\6c";
}
.icon-doc-file:before {
  content: "\6d";
}
.icon-folders:before {
  content: "\6e";
}
.icon-text-list-file:before {
  content: "\6f";
}
.icon-file-history:before {
  content: "\70";
}
.icon-empty-file:before {
  content: "\71";
}
.icon-code-file:before {
  content: "\72";
}
.icon-text-file1:before {
  content: "\73";
}
.icon-text-list-file1:before {
  content: "\74";
}
.icon-empty-file1:before {
  content: "\75";
}
.icon-folder:before {
  content: "\76";
}
.icon-books:before {
  content: "\77";
}
.icon-books1:before {
  content: "\78";
}
.icon-network-folders:before {
  content: "\79";
}
.icon-calendar2:before {
  content: "\7a";
}
.icon-angle-up:before {
  content: "\7b";
}
.icon-caret-left:before {
  content: "\7c";
}
.icon-caret-right:before {
  content: "\7d";
}
.icon-caret-down-two:before {
  content: "\7e";
}
.icon-left-open-big:before {
  content: "\e000";
}
.icon-up-open-big:before {
  content: "\e001";
}
.icon-right-open-big:before {
  content: "\e002";
}
.icon-down-open-big:before {
  content: "\e003";
}
.icon-down-open:before {
  content: "\e004";
}
.icon-left-open:before {
  content: "\e005";
}
.icon-right-open:before {
  content: "\e006";
}
.icon-up-open:before {
  content: "\e007";
}
.icon-arrow-down:before {
  content: "\e008";
}
.icon-arrow-left:before {
  content: "\e009";
}
.icon-arrow-right:before {
  content: "\e00a";
}
.icon-arrow-up:before {
  content: "\e00b";
}
.icon-reload3:before {
  content: "\e00c";
}
.icon-chevron-up-1:before {
  content: "\e00d";
}
.icon-chevron-right-1:before {
  content: "\e00e";
}
.icon-chevron-left-1:before {
  content: "\e00f";
}
.icon-chevron-down-1:before {
  content: "\e010";
}
.icon-arrow-swap:before {
  content: "\e011";
}
.icon-exchange:before {
  content: "\e012";
}
.icon-angle-double-down:before {
  content: "\e013";
}
.icon-download3:before {
  content: "\e014";
}
.icon-download-1:before {
  content: "\e015";
}
.icon-close-button:before {
  content: "\e016";
}
.icon-close:before {
  content: "\e017";
}
.icon-close-round:before {
  content: "\e018";
}
.icon-cross-mark:before {
  content: "\e019";
}
.icon-cancel:before {
  content: "\e01a";
}
.icon-remove:before {
  content: "\e01b";
}
.icon-lock:before {
  content: "\e01c";
}
.icon-unlock-alt:before {
  content: "\e01d";
}
.icon-user-outline:before {
  content: "\e01e";
}
.icon-book:before {
  content: "\e01f";
}
.icon-content-7:before {
  content: "\e020";
}
.icon-folder-o:before {
  content: "\e021";
}
.icon-folder-open-o:before {
  content: "\e022";
}
.icon-folder-open:before {
  content: "\e023";
}
.icon-folder-1:before {
  content: "\e024";
}
.icon-file-close-alt:before {
  content: "\e025";
}
.icon-file-open:before {
  content: "\e026";
}
.icon-file-close:before {
  content: "\e027";
}
.icon-file-alt:before {
  content: "\e028";
}
.icon-draw:before {
  content: "\e029";
}
.icon-editing:before {
  content: "\e02a";
}
.icon-edit:before {
  content: "\e02b";
}
.icon-trash-o:before {
  content: "\e02c";
}
.icon-sort:before {
  content: "\e02d";
}
.icon-times:before {
  content: "\e02e";
}
.icon-close-1:before {
  content: "\e02f";
}
.icon-search-1:before {
  content: "\e030";
}
.icon-search-icon:before {
  content: "\e031";
}
.icon-search-1-1:before {
  content: "\e032";
}
.icon-search-2:before {
  content: "\e033";
}
.icon-search-find:before {
  content: "\e034";
}
.icon-id-card:before {
  content: "\e035";
}
.icon-resume:before {
  content: "\e036";
}
.icon-plus:before {
  content: "\e037";
}
.icon-plus-circle:before {
  content: "\e038";
}
.icon-plus-1:before {
  content: "\e039";
}
.icon-plus-circled:before {
  content: "\e03a";
}
.icon-plus-square:before {
  content: "\e03b";
}
.icon-menu-3:before {
  content: "\e03c";
}
.icon-list:before {
  content: "\e03d";
}
.icon-menu-1:before {
  content: "\e03e";
}
.icon-bars2:before {
  content: "\e03f";
}
.icon-content-44:before {
  content: "\e040";
}
.icon-table:before {
  content: "\e041";
}
.icon-add:before {
  content: "\e042";
}
.icon-question:before {
  content: "\e043";
}
.icon-folder2:before {
  content: "\e044";
}
.icon-folder-2:before {
  content: "\e045";
}
.icon-folder-1-1:before {
  content: "\e046";
}
.icon-pdf-file-format-symbol:before {
  content: "\e047";
}
.icon-file-pdf-o2:before {
  content: "\e048";
}
.icon-file-word-o:before {
  content: "\e049";
}
.icon-file-text-o:before {
  content: "\e04a";
}
.icon-file-powerpoint-o:before {
  content: "\e04b";
}
.icon-file-image-o:before {
  content: "\e04c";
}
.icon-files-o:before {
  content: "\e04d";
}
.icon-file-archive-o2:before {
  content: "\e04e";
}
.icon-file-code-o:before {
  content: "\e04f";
}
.icon-file-excel-o2:before {
  content: "\e050";
}
.icon-file-o:before {
  content: "\e051";
}
.icon-page-add:before {
  content: "\e052";
}
.icon-file-add:before {
  content: "\e053";
}
.icon-file-settings:before {
  content: "\e054";
}
.icon-align-center:before {
  content: "\e055";
}
.icon-files:before {
  content: "\e056";
}
.icon-file-code:before {
  content: "\e057";
}
.icon-file-binary:before {
  content: "\e058";
}
.icon-file-pdf2:before {
  content: "\e059";
}
.icon-gallery:before {
  content: "\e05a";
}
.icon-tool:before {
  content: "\e05b";
}
.icon-paperclip1:before {
  content: "\e05c";
}
.icon-content-41:before {
  content: "\e05d";
}
.icon-content-43:before {
  content: "\e05e";
}
.icon-eye-1:before {
  content: "\e05f";
}
.icon-check-mark:before {
  content: "\e060";
}
.icon-round-1:before {
  content: "\e061";
}
.icon-signs:before {
  content: "\e062";
}
.icon-lock-1:before {
  content: "\e063";
}
.icon-unlock:before {
  content: "\e064";
}
.icon-lock-2:before {
  content: "\e065";
}
.icon-unlocked:before {
  content: "\e066";
}
.icon-user-1:before {
  content: "\e067";
}
.icon-man-people-streamline-user:before {
  content: "\e068";
}
.icon-power:before {
  content: "\e069";
}
.icon-power-1:before {
  content: "\e06a";
}
.icon-log-out:before {
  content: "\e06b";
}
.icon-arrow-left-1:before {
  content: "\e06c";
}
.icon-arrows-1:before {
  content: "\e06d";
}
.icon-download-2:before {
  content: "\e06e";
}
.icon-arrow-circle-down-line:before {
  content: "\e06f";
}
.icon-download-1-1:before {
  content: "\e070";
}
.icon-home-1:before {
  content: "\e071";
}
.icon-home-house-streamline:before {
  content: "\e072";
}
.icon-home-button:before {
  content: "\e073";
}
.icon-correct:before {
  content: "\e074";
}
.icon-mail2:before {
  content: "\e917";
}
.icon-mail-envelope-closed:before {
  content: "\e918";
}
.icon-mail-envelope-closed2:before {
  content: "\e919";
}
.icon-inbox-upload2:before {
  content: "\e91a";
}
.icon-inbox-upload3:before {
  content: "\e91b";
}
.icon-inbox-document-text:before {
  content: "\e91c";
}
.icon-inbox-document-text2:before {
  content: "\e91d";
}
.icon-inbox-document:before {
  content: "\e91e";
}
.icon-inbox-download:before {
  content: "\e91f";
}
.icon-inbox-upload:before {
  content: "\e920";
}
.icon-chart:before {
  content: "\e921";
}
.icon-site-map:before {
  content: "\e922";
}
.icon-alarm-clock:before {
  content: "\e923";
}
.icon-justice:before {
  content: "\e924";
}
.icon-chat:before {
  content: "\e925";
}
.icon-retweet:before {
  content: "\e926";
}
.icon-resize:before {
  content: "\e927";
}
.icon-upload2:before {
  content: "\e928";
}
.icon-download4:before {
  content: "\e929";
}
.icon-archive2:before {
  content: "\e92a";
}
.icon-arrow-minimise:before {
  content: "\e92b";
}
.icon-vpn_key:before {
  content: "\e92c";
}
.icon-group_add:before {
  content: "\e92d";
}
.icon-account_balance:before {
  content: "\e92e";
}
.icon-question_answer:before {
  content: "\e92f";
}
.icon-compare_arrows:before {
  content: "\e930";
}
.icon-crop:before {
  content: "\e931";
}
.icon-edit-copy:before {
  content: "\e932";
}
.icon-notifications-outline:before {
  content: "\e935";
}
.icon-conversation:before {
  content: "\e936";
}
.icon-stats:before {
  content: "\e937";
}
.icon-stats2:before {
  content: "\e938";
}
.icon-loop-alt4:before {
  content: "\e939";
}
.icon-image:before {
  content: "\e93a";
}
.icon-library2:before {
  content: "\e93b";
}
.icon-file-text:before {
  content: "\e93c";
}
.icon-profile2:before {
  content: "\e93d";
}
.icon-file-empty:before {
  content: "\e93e";
}
.icon-files-empty:before {
  content: "\e93f";
}
.icon-file-text2:before {
  content: "\e940";
}
.icon-file-picture:before {
  content: "\e941";
}
.icon-clone:before {
  content: "\e942";
}
.icon-file-archive-o:before {
  content: "\e943";
}
.icon-file-zip:before {
  content: "\e944";
}
.icon-copy:before {
  content: "\e945";
}
.icon-paste:before {
  content: "\e946";
}
.icon-arrows-h:before {
  content: "\e947";
}
.icon-television:before {
  content: "\e948";
}
.icon-file-pdf-o:before {
  content: "\e949";
}
.icon-file-excel-o:before {
  content: "\e94a";
}
.icon-display:before {
  content: "\e956";
}
.icon-download22:before {
  content: "\e962";
}
.icon-upload3:before {
  content: "\e963";
}
.icon-enlarge2:before {
  content: "\e98b";
}
.icon-shrink2:before {
  content: "\e98c";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification2:before {
  content: "\ea08";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-loop2:before {
  content: "\ea2d";
}
.icon-crop2:before {
  content: "\ea57";
}
.icon-pagebreak:before {
  content: "\ea6e";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-in-alt:before {
  content: "\e951";
}
.icon-explore:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e900";
}
.icon-view:before {
  content: "\e900";
}
.icon-look:before {
  content: "\e900";
}
.icon-examine:before {
  content: "\e900";
}
.icon-analyse:before {
  content: "\e900";
}
.icon-at:before {
  content: "\e901";
}
.icon-email:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-profile:before {
  content: "\e902";
}
.icon-account:before {
  content: "\e902";
}
.icon-avatar:before {
  content: "\e902";
}
.icon-hash:before {
  content: "\e903";
}
.icon-office:before {
  content: "\e904";
}
.icon-folder-download:before {
  content: "\e933";
}
.icon-folder-upload:before {
  content: "\e934";
}
.icon-history:before {
  content: "\e94d";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-stopwatch:before {
  content: "\e952";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-download2:before {
  content: "\e960";
}
.icon-upload:before {
  content: "\e961";
}
.icon-cog:before {
  content: "\e994";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-eye2:before {
  content: "\e9ce";
}
.icon-data-viewer:before {
  content: "\e94b";
}
.icon-eye:before {
  content: "\e90b";
}
.icon-building-o:before {
  content: "\e90c";
}
.icon-mail1:before {
  content: "\e94c";
}
.icon-download:before {
  content: "\e905";
}
.icon-drawer:before {
  content: "\e905";
}
.icon-save:before {
  content: "\e905";
}
.icon-store:before {
  content: "\e905";
}
.icon-archive:before {
  content: "\e905";
}
.icon-email2:before {
  content: "\e906";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-letter:before {
  content: "\e906";
}
.icon-message:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e906";
}
.icon-call_split:before {
  content: "\e907";
}
.icon-event_note:before {
  content: "\e908";
}
.icon-pie-chart2:before {
  content: "\e909";
}
.icon-hash2:before {
  content: "\e90a";
}
.icon-users:before {
  content: "\e94e";
}
.icon-file-plus:before {
  content: "\e954";
}
.icon-menu1:before {
  content: "\e955";
}
.icon-view-tile:before {
  content: "\e90d";
}
.icon-view-list:before {
  content: "\e90e";
}
.icon-view-carousel:before {
  content: "\e90f";
}
.icon-library:before {
  content: "\e910";
}
.icon-user-solid-circle:before {
  content: "\e911";
}
.icon-user-add:before {
  content: "\e912";
}
.icon-user2:before {
  content: "\e913";
}
.icon-text-box:before {
  content: "\e914";
}
.icon-chart-pie:before {
  content: "\e915";
}
.icon-view-tile2:before {
  content: "\e916";
}
.icon-menu:before {
  content: "\e957";
}
