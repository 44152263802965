.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}


.img-30{
  height: 30px;
}

.nav-40{
  height: 40px;
}


ul {
  list-style: none;
  justify-content: space-around;
}

.active{
 
  color: #fff !important;
}

.side-bar{
  background: #2F419B;
}

.container-page{
  height: 100%;
  position: absolute;
}

.page-load{
  margin-top: 10px;
  padding-top: 60px;
  float: left;
  width: 101%;
  margin-bottom: 10px;
  max-height: 98vh;
  overflow: auto;
}

.header-Info{
  width: 100%;
  text-align: right;
}

.margin-left-10{
  margin-left: 10px;
}

.height-100{
  height: 100%;
}

.filter-label{
  width: 100%;
}

.toast.toastpositon {
  
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 100000;
}
.success-toast .toast-header {
  background: #d9ffd9;
  display: block;
}

.success-toast .toast-body {
  background: #eeffee;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  line-height: 45px;
}

.nav ul li a {
  color: #c5c5c5;
  text-decoration: none;
}
.nav ul li a i {
  font-size: 18px;
  padding-right: 11px;
  position: relative;
  top: 2px;
}
.nav ul li a.active {
  font-weight: 600;
}

.loader_demo {
  position: fixed;
  top: 0;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  text-align: center;
}
.loader_demo img {
  margin: 18%  0 0 10%;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  font-size: 12px !important;
}

.refresh-btn{
  float: right;
    padding: 3px 3px 3px 3px !important;
    margin-top: -35px !important;
}



